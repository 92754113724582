import React, { useRef, useState, useEffect } from "react"

import emailjs from "emailjs-com"

import {
  ContactContainer,
  LocationContainer,
  ContactColumn,
  FormContainer,
  FormContent,
} from "./ContactInfo.styles"

import * as Yup from "yup"
import { useFormik } from "formik"

import { db } from "../../firebase/config"
import { addDoc, collection } from "firebase/firestore"

import Loader from "../../components/Loader"
import { useAuthContext } from "../../hooks/useAuthContext"
import useWidget from "../../hooks/useWidget"

const ContactInfo = ({ widget1, widget2, arabic }) => {
  const [messageSent, setMessageSent] = useState(null)
  // const [firstName, setFirstName] = useState('')
  // const [lastName, setLastName] = useState('')
  // const [companyName, setCompanyName] = useState('')
  // const [emailAddress, setEmailAddress] = useState('')
  // const [message, setMessage] = useState('')

  const colRef = collection(db, "contact")
  const { user, authIsReady } = useAuthContext()

  const form = useRef()
  const validation = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      emailAddress: "",
      number: "",
      message: "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required(
        arabic == false ? "This Field is required" : "هذه الخانة الزامية"
      ),
      lastName: Yup.string().required(
        arabic == false ? "This Field is required" : "هذه الخانة الزامية"
      ),
      emailAddress: Yup.string().required(
        arabic == false ? "This Field is required" : "هذه الخانة الزامية"
      ),
      number: Yup.string().required(
        arabic == false ? "This Field is required" : "هذه الخانة الزامية"
      ),
      message: Yup.string().required(
        arabic == false ? "This Field is required" : "هذه الخانة الزامية"
      ),
    }),

    onSubmit: values => {
      addDoc(colRef, {
        firstName: values.firstName,
        lastName: values.lastName,
        companyName: values.companyName,
        emailAddress: values.emailAddress,
        message: values.message,
        number: values.number,
      })
      emailjs.send(
        "opd_mailing_service",
        "template_sdxewsf",
        {
          from_name: values.firstName + " " + values.lastName,
          to_name: "mr Muhammad Itani",
          message: values.message,
          company: values.companyName ? values.companyName : " ",
          email: values.emailAddress,
          number: values.number,
        },
        "g1dJssA6i85wnSF7J"
      )
      setMessageSent("True")
    },
  })

  // const handleSubmitForm = e => {
  //   e.preventDefault()

  //   emailjs
  //     .sendForm(
  //       "opd_mailing_service",
  //       "template_aed9uc5",
  //       form.current,
  //       "g1dJssA6i85wnSF7J"
  //     )
  //     .then(() => {
  //       e.target.reset()
  //       setMessageSent(true)
  //     })
  //     .catch(() => {
  //       alert("An error has occured. Please try later")
  //     })
  // }

  // fetch data from widgets
  const widgetData2 = useWidget("get-in-touch")[0]
  const widgetData = useWidget("contact-info")[0]
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [widgetData])

  useEffect(() => {
    setLoading2(false)
  }, [widgetData2])

  if (loading && loading2) {
    return <Loader />
  } else {
    return (
      <ContactContainer>
        <div className="container">
          <h2 className={arabic == false ? "" : "text-end"}>
            {widgetData && arabic == false
              ? widgetData.title
              : widgetData.title_ar}
          </h2>
          <LocationContainer>
            <div className="row">
              {widgetData &&
                widgetData.details &&
                widgetData.details.map(record => {
                  return (
                    <div className="col">
                      <ContactColumn
                        className={arabic == false ? "" : "text-end"}
                      >
                        <h4>
                          {arabic == false ? record.title : record.title_ar}
                        </h4>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              arabic == false
                                ? record.description
                                : record.description_ar,
                          }}
                        />
                      </ContactColumn>
                    </div>
                  )
                })}
                <div>
                  <br/><br/><br/>
                  {arabic == false ? "DOT VIP A Digital Real Estate Marketing Agency " : "DOT VIP وكالة تسويق عقاري رقمي، "}
                  {arabic == false ? "Dear user, " : "عزيزي المستخدم، "}
                  {arabic == false ? "If you have any questions or enquiries about using our website, " : "إذا كانت لديك أي أسئلة أو استفسارات حول استخدام موقعنا، "}
                  {arabic == false ? "kindly, feel free to ask our WhatsApp support group. Just click and join. Support is one click away." : "فيرجى عدم التردد في طرح سؤالك على مجموعة دعم WhatsApp الخاصة بنا. فقط انقر وانضم. الدعم بنقرة واحدة."}
                  <a href="https://chat.whatsapp.com/LekC74EPBGbH7m9jM5sb20" >{arabic == false ? "Click here to join our whatsapp group!" : "اكبس هنا للانضمام إلى مجموعة WhatsApp الخاصة بنا!"}</a>
                  <p>{arabic == false ? "ُThank You" : "شكرًا لك"}</p>
                </div>
            </div>
          </LocationContainer>
          <FormContainer className={arabic == false ? "" : "text-end"}>
            <h2 className={arabic == false ? "" : "text-end w-50"}>
              {widgetData2 && arabic == false
                ? widgetData2.title
                : widgetData2.title_ar}
            </h2>
            <p>
              {widgetData2 && arabic == false
                ? widgetData2.description
                : widgetData2.description_ar}
            </p>
          </FormContainer>
          <FormContent>
            <div className="form">
              <form
                ref={form}
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                }}
              >
                <div
                  className={
                    arabic == false ? "input" : "input align-items-end"
                  }
                >
                  <label htmlFor="first_name">
                    {arabic == false ? "FIRST NAME*" : "الاسم الاول*"}
                  </label>
                  <input
                    className={arabic == false ? "" : "text-end"}
                    type="text"
                    name="firstName"
                    id="first_name"
                    required
                    placeholder={
                      arabic == false ? "Enter First Name" : "أدخل الاسم الأول"
                    }
                    onChange={validation.handleChange}
                    value={validation.values.firstName}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <p className="error pt-2">{validation.errors.firstName} </p>
                  ) : null}
                </div>
                <div
                  className={
                    arabic == false ? "input" : "input align-items-end"
                  }
                >
                  <label htmlFor="last_name">
                    {arabic == false ? "LAST NAME*" : "إسم العائلة*"}
                  </label>
                  <input
                    className={arabic == false ? "" : "text-end"}
                    type="text"
                    name="lastName"
                    id="last_name"
                    required
                    placeholder={
                      arabic == false ? "Enter Last Name" : "إسم العائلة*"
                    }
                    onChange={validation.handleChange}
                    value={validation.values.lastName}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <p className="error pt-2">{validation.errors.lastName} </p>
                  ) : null}
                </div>
                <div
                  className={
                    arabic == false ? "input" : "input align-items-end"
                  }
                >
                  <label htmlFor="company_name">
                    {arabic == false ? "COMPANY NAME" : "اسم الشركة"}
                  </label>
                  <input
                    className={arabic == false ? "" : "text-end"}
                    type="text"
                    name="companyName"
                    id="comapny_name"
                    placeholder={
                      arabic == false ? "Enter company Name" : "أدخل الشركة"
                    }
                    onChange={validation.handleChange}
                    value={validation.values.companyName}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.companyName &&
                  validation.errors.companyName ? (
                    <p className="error pt-2">
                      {validation.errors.companyName}{" "}
                    </p>
                  ) : null}
                </div>
                <div
                  className={
                    arabic == false ? "input" : "input align-items-end"
                  }
                >
                  <label htmlFor="email_address">
                    {arabic == false ? "EMAIL ADDRESS*" : " البريد الالكترونى*"}
                  </label>
                  <input
                    className={arabic == false ? "" : "text-end"}
                    type="email"
                    name="emailAddress"
                    id="email_address"
                    required
                    placeholder={
                      arabic == false
                        ? "Enter email address"
                        : "أدخل  البريد الالكتروني"
                    }
                    onChange={validation.handleChange}
                    value={validation.values.emailAddress}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.emailAddress &&
                  validation.errors.emailAddress ? (
                    <p className="error pt-2">
                      {validation.errors.emailAddress}{" "}
                    </p>
                  ) : null}
                </div>
                <div
                  className={
                    arabic == false ? "input" : "input align-items-end"
                  }
                >
                  <label htmlFor="number">
                    {arabic == false ? "PHONE NUMBER*" : "رقم الهاتف*"}
                  </label>
                  <input
                    className={arabic == false ? "" : "text-end"}
                    type="phone"
                    name="number"
                    id="number"
                    required
                    placeholder={arabic == false ? "phone number" : "رقم الهاتف*"}
                    onChange={validation.handleChange}
                    value={validation.values.number}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.number && validation.errors.number ? (
                    <p className="error pt-2">{validation.errors.number} </p>
                  ) : null}
                </div>
                <div
                  className={
                    arabic == false ? "input" : "input align-items-end"
                  }
                >
                  <label htmlFor="message">
                    {arabic == false ? "MESSAGE*" : "رسالة*"}
                  </label>
                  <textarea
                    className={arabic == false ? "" : "text-end"}
                    name="message"
                    id="message"
                    rows="10"
                    required
                    placeholder={
                      arabic == false ? "Write a message" : "اكتب رسالة"
                    }
                    onChange={validation.handleChange}
                    value={validation.values.message}
                    onBlur={validation.handleBlur}
                  ></textarea>
                  {validation.touched.message && validation.errors.message ? (
                    <p className="error pt-2">{validation.errors.message} </p>
                  ) : null}
                </div>
                <div className="submit">
                  <input
                    type="submit"
                    value={arabic == false ? "SUBMIT MESSAGE" : "إرسال"}
                  />
                </div>
              </form>
              {messageSent && (
                <p className="message-recieved">
                  {arabic == false
                    ? "Your message has been recieved. We will be in contact with your shortly"
                    : "وقد وردت الرسالة. سنكون على اتصال بك قريبا"}
                </p>
              )}
            </div>
            <div className="map"></div>
          </FormContent>
        </div>
      </ContactContainer>
    )
  }
}

export default ContactInfo
