import { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/config"
const useWidget = (name) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchNav = async () => {
        const q = query(collection(db, "widgets"), where("name", "==", name));
        const querySnapshot = await getDocs(q);
        let arr = {}
        querySnapshot.forEach((doc) => {
            arr=doc.data()
        });
        setData(arr);
      }
  
      fetchNav()
  }, [name]);
   
  return [data];
};

export default useWidget;