import React, { useContext } from "react"
import ContactInfo from "../components/ContactInfo"
import Hero from "../components/Hero"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { StateContext } from "../helpers/StateProvider"

const Contact = props => {
  const { authIsReady } = useAuthContext()
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {authIsReady && (
        <>
          <>
            <Seo title="Contact" />
            <Hero
              title={
                props.data.widget3 && language == false
                  ? props.data.widget3.title
                  : props.data.widget3.title_ar
              }
              subTitle={
                props.data.widget3 && language == false
                  ? props.data.widget3.description
                  : props.data.widget3.description_ar
              }
              arabic={language}
            />
            <ContactInfo
              widget1={props.data.widget1}
              widget2={props.data.widget2}
              arabic={language}
            />
          </>
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }

    widget1: widget(name: { eq: "contact-info" }) {
      title_ar
      description_ar
      link_text_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        description
        image

        link
        svg
        title
        title_ar
      }
    }
    widget2: widget(name: { eq: "get-in-touch" }) {
      title_ar
      description_ar
      link_text_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
        title_ar
      }
    }
    widget3: widget(name: { eq: "contact-us" }) {
      title_ar
      description_ar
      link_text_ar
      id
      image

      link
      link_text
      name
      title

      type
      description
      details {
        description
        image

        link
        svg
        title
        title_ar
      }
    }
  }
`

export default Contact
