import styled from "styled-components"

export const ContactContainer = styled.section`
  padding: 70px 0;

  h2 {
    font-size: 1.8rem;
  }
`

export const LocationContainer = styled.div`
  .row {
    margin-top: 40px;
  }
`

export const ContactColumn = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-weight: normal;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 2px;
    font-size: 0.9rem;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 576px) {
    margin-bottom: 30px;
  }
`

export const FormContainer = styled.div`
  margin-top: 50px;
  p {
    font-size: 0.9rem;
    width: 50%;

    @media (max-width: 576px) {
      width: 100%;
    }
  }
`

export const FormContent = styled.div`
  display: flex;
  align-items: stretch;

  .error {
    color:red;
  }

  p.message-recieved {
    text-align: center;
    color: white;
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }

  .form {
    width: 50%;
    background-color: var(--darkBlue);

    form {
      width: 80%;
      margin: 50px auto;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        width: 100%;

        label {
          color: white;
          font-weight: bold;
          font-size: 0.9rem;
        }

        input,
        textarea {
          padding: 10px 6px;
          background: none;
          border: 1px solid white;
          border-radius: 5px;
          width: 100%;
          color: white;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: white;
            opacity: 80%;
          }
        }
      }

      .submit {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        @media (max-width: 576px) {
          justify-content: center;

          input {
            width: 100%;
          }
        }

        input {
          background-color: var(--lightGreen);
          padding: 10px 30px;
          color: white;
          border: none;
          border-radius: 5px;
        }
      }
    }

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .map {
    width: 44% !important;
    height: 783px !important;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`
